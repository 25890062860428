import React, { Component } from "react";
import { connect } from "react-redux";
import DetailedLocationReview from "../DetailedLocationReview";
import { ServiceDetailsOverlayContainer } from "../../components/ServiceDetailsOverlay/ServiceDetailsOverlayContainer.js";
import StarRating from "../../components/starrating";
import GoogleMap from "../../components/google-map";
import _parseInt from "lodash/parseInt";
import _find from "lodash/find";
import {
  clearCCardInfo,
  updateCCardInfo,
  updateDestination,
  updateDestinationAddress,
  updateLoggedInFlag,
  updatePpuFlag,
  updateServiceInfo,
  updateServiceTransition,
  updateTowDistance,
} from "../../action";
import { generatePath } from "../../utilities/RedirectUtils";
import ModalDialogAlt from "../../components/modal-dialog-alt";
import SlideArrow from "../../components/svg/SlideArrow";
import getAmcOrJJCoverageAmount from "../../utils/getAmcOrJJCoverageAmount";
import titleCase from "../../utils/titleCase";
import AlertModal from "../../components/alertmodal";
import { TOW, TOW_SERVICE_ID } from "../../app-consts/appConstants";
import getBenefitAmount from "../../utils/getBenefitAmount";
import { sendDestinationLocationToJacada } from "../../jacada/sendJacadaEventData";
import { LocaleConsumer } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import {
  getTextByUnits,
  statusTranslatedText,
} from "../../utils/translatedTextUtils";
import { cookieMatcher } from "../../utils/cookieUtils";
import { datadogRum } from "@datadog/browser-rum";
import { Button } from "mesh-component-library";
import { getTranslatedOperatingHoursText } from "../../utils/translatedTextUtils";
import {getOmniStreamlinedFlag, hasPepSessionSelector} from "../../selector";
import { UNABLE_TO_COMPLETE_SERVICE } from "../../app-consts/errorMessageConstants";

export class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderDestinationFullDetailsModal: false,
      serviceNotAvailable: false,
      invalidAddress: false,
      displayOverMilesModal: false,
      coveredMilesLimit: "",
      pricePerAdditionalMile: "",
    };
  }

  componentDidMount() {
    document.title = "Location | Roadside Assistance";
    let {
      match: {
        params: { usertype },
      },
      partnerDetails: { displayCode, experience },
      serviceDetails: { serviceDetails },
      partnerDetails,
      commonBenefits,
      submitRequest,
      serviceRequest,
    } = this.props;

    if (submitRequest.success) {
      const { sessionId, callId, requestId, usageId } = submitRequest.response;
      this.props.history.push(
        generatePath(`tracker/${sessionId}/${callId}/${requestId}/${usageId}`)
      );
    }

    const { ppuEnabled, benefitsEnabled } = experience;

    if (!partnerDetails?.experience?.serviceCost?.serviceCosts?.length) {
      if (usertype === "benefits") {
        let cookieMatches = cookieMatcher();
        let memberId = cookieMatches
          ? JSON.parse(cookieMatches.pop()).membershipId
          : undefined;

        if (memberId) {
          this.props.history.push(generatePath("disablementlocation"));
        }
      }

      if (usertype === "OmniAssist") {
        if (displayCode !== "PEP") {
          this.props.history.push(generatePath("disablementlocation"));
        }
      }

      let serviceText = localStorage.getItem("serviceText");
      let service = _find(serviceDetails, ["serviceText", serviceText]);
      this.props.updateServiceInfo(service);
    }

    if (serviceRequest.disablementAddress.addressLine1 === "") {
      this.props.history.push(generatePath("disablementlocation"));
    }

    if (
      (this.props.serviceInfo.serviceText === "Tow" &&
        experience.milesBased &&
        commonBenefits.services.length) ||
      (!ppuEnabled && !benefitsEnabled) ||
      commonBenefits.noBenefits
    ) {
      this.setOverMileInfoInState(commonBenefits, experience);
    }
  }

  //TODO - evaluate for benefits flow that turns ppu (make a UI test)
  setOverMileInfoInState = (commonBenefits, experience) => {
    let services = commonBenefits?.benefits
      ? commonBenefits.services
      : experience.serviceCost.serviceCosts;
    let towServiceInfo = services.find(
      (service) => service.type === "Tow" || service.serviceType === "Tow"
    );

    let coveredMilesLimit =
      towServiceInfo.towIncludedQty ||
      parseInt(towServiceInfo.towIncludedDistanceQty);
    let pricePerAdditionalMile =
      towServiceInfo.towExtraUnitAmt || towServiceInfo.costPerMile;

    this.setState({
      coveredMilesLimit,
      pricePerAdditionalMile,
    });
  };

  confirmDestination = (
    overMileageCost,
    overMileageDistance,
    distanceFromCurrentLocation,
    unitsShorthand
  ) => {
    let {
      destination,
      serviceRequest,
      partnerDetails: { experience },
      commonBenefits,
      serviceInfo,
      hasPepSession,
      omniStreamlined
    } = this.props;
    let { milesBased, overMileageEnabled } = experience;
    const { serviceText = "" } = serviceInfo;

    sendDestinationLocationToJacada(destination.addressDetails);

    if (
      milesBased &&
      !overMileageEnabled &&
      !commonBenefits.noBenefits &&
      overMileageDistance > 0
    ) {
      this.setState({ displayOverMilesModal: true });
    } else {
      destination.hasBeenConfirmed = true;
      this.props.updateDestination(destination);
      if (overMileageDistance > 0) {
        this.props.updateCCardInfo({ transactionAmount: overMileageCost });
        this.props.updatePpuFlag("Y");
        if (commonBenefits.benefits && serviceRequest.loggedIn === "N") {
          this.props.updateLoggedInFlag("Y");
        }
      } else if (commonBenefits.benefits) {
        this.props.clearCCardInfo({});
        this.props.updatePpuFlag("N");
      }

      if (serviceRequest.ppuFlag === "Y") {
        this.props.updateCCardInfo({
          transactionAmount: destination.towInfo?.totalTowCost,
        });
      }

      if (
        (!experience.ppuEnabled && !experience.benefitsEnabled) ||
        (hasPepSession && serviceText === TOW)
      ) {
        this.props.clearCCardInfo({});
        this.props.updatePpuFlag("N");
        this.props.updateLoggedInFlag("Y");
      }
      if (omniStreamlined) {
        this.props.updateTowDistance({
          scalar: parseFloat(distanceFromCurrentLocation),
          unitMeasure: unitsShorthand,
        });
      }

      this.props.updateDestinationAddress(destination.addressDetails);
      datadogRum.addAction("Confirm destination address");
    }
  };

  getDetailedLocation = () => {
    this.setState({
      renderDestinationFullDetailsModal: true,
    });
  };

  closeDetailedLocationReview = () => {
    this.setState({
      renderDestinationFullDetailsModal: false,
    });
  };

  closeServiceNotAvailableModal = () => {
    this.setState({ serviceNotAvailable: false });
  };

  openServiceNotAvailableModal = () => {
    this.setState({ serviceNotAvailable: true });
  };

  closeInvalidModal = () => {
    this.setState({ invalidAddress: false });
  };

  openInvalidAddressModal = () => {
    this.setState({ invalidAddress: true });
  };

  getLat = () => {
      return 39.82835;
  };

  getLng = () => {
      return -98.5816684;
  };

  handlePhoneCall = () => {
    const phone =
      this.props.partnerDetails.phone || localStorage.getItem("callbackNumber");
    window.location.href = "tel:" + phone;
  };

  closeOverMilesModal = () => {
    this.setState({ displayOverMilesModal: false });
  };

  ppuExtraCostBreakdown = (
    serviceInfo,
    ppuEnabled,
    benefitsEnabled,
    overMileageDistance,
    commonBenefits,
    destination,
    getTranslatedText
  ) => {
    const {
      towInfo: { distanceUnits, overMileageCost, totalTowCost },
    } = destination;

    const { partnerDetails } = this.props;

    if (
      !!partnerDetails?.experience?.towCostLimit &&
      _parseInt(totalTowCost) >
        _parseInt(partnerDetails?.experience?.towCostLimit)
    ) {
      this.props.updateServiceTransition({
        errorMessageContent: UNABLE_TO_COMPLETE_SERVICE,
      });
      this.props.history.push(generatePath("ServiceTransition"));
      return;
    }

    return (
      <div id={"ppu-extra-cost"}>
        <div className="location__detailed--extraCost">
          <p>{`${serviceInfo.towIncludedDistanceQty} ${
            distanceUnits === "kilometer"
              ? titleCase(
                  getTranslatedText(TRANSLATION_CONSTANTS.KILOMETERS_INCLUDED)
                )
              : getTranslatedText(TRANSLATION_CONSTANTS.MILES_INCLUDED)
          }`}</p>
          <p>
            {ppuEnabled || (!ppuEnabled && !benefitsEnabled)
              ? `$${serviceInfo.originalCost}`
              : "$0.00"}
          </p>
          <p>{`${overMileageDistance} ${
            distanceUnits === "kilometer"
              ? titleCase(
                  getTranslatedText(TRANSLATION_CONSTANTS.ADDITIONAL_KILOMETERS)
                )
              : titleCase(
                  getTranslatedText(TRANSLATION_CONSTANTS.ADDITIONAL_MILES)
                )
          }`}</p>
          {!commonBenefits.benefits && <p>{`$${overMileageCost}`}</p>}
        </div>
        <p className="location__detailed--extraCostDesc">{`+ $${
          serviceInfo.costPerMile
        }/${
          distanceUnits === "kilometer"
            ? getTranslatedText(TRANSLATION_CONSTANTS.KILOMETER)
            : getTranslatedText(TRANSLATION_CONSTANTS.MILE)
        } ${getTranslatedText(TRANSLATION_CONSTANTS.OVER2)} ${
          serviceInfo.towIncludedDistanceQty
        } ${
          distanceUnits === "kilometer"
            ? getTranslatedText(TRANSLATION_CONSTANTS.KILOMETERS)
            : getTranslatedText(TRANSLATION_CONSTANTS.MILES)
        }`}</p>
        {commonBenefits.benefits && (
          <div className="location__detailed--extraCost">
            <p>{getTranslatedText(TRANSLATION_CONSTANTS.COVERAGE_UP_TO)}</p>
            <p>-${getAmcOrJJCoverageAmount(commonBenefits)}</p>
          </div>
        )}
        <div className="location__detailed--extraCostTotal">
          <div className="location__detailed--extraCostTotalInner">
            <p>
              {getTranslatedText(TRANSLATION_CONSTANTS.ESTIMATED_AMOUNT_DUE)}
            </p>
            <p>{`$${totalTowCost}`}</p>
          </div>
        </div>
      </div>
    );
  };

  benefitUserExtraCostBreakdown = (
    overMileageDistance,
    overMileageCost,
    units,
    getTranslatedText
  ) => {
    const { partnerDetails } = this.props;

    if (
      !!partnerDetails?.experience?.towCostLimit &&
      _parseInt(overMileageCost) >
        _parseInt(partnerDetails?.experience?.towCostLimit)
    ) {
      this.props.updateServiceTransition({
        errorMessageContent: UNABLE_TO_COMPLETE_SERVICE,
      });
      this.props.history.push(generatePath("ServiceTransition"));
      return;
    }
    return (
      <div id={"benefit-user-extra-cost"}>
        <div className="location__detailed--extraCost">
          <p>
            {this.state.coveredMilesLimit}{" "}
            {getTextByUnits(
              units,
              TRANSLATION_CONSTANTS.MILES_INCLUDED,
              TRANSLATION_CONSTANTS.KILOMETERS_INCLUDED
            )}
          </p>
          <p>$0.00</p>
          <p>
            {overMileageDistance}{" "}
            {getTranslatedText(TRANSLATION_CONSTANTS.ADDITIONAL)}{" "}
            {titleCase(units)}
          </p>
          <p>${overMileageCost}</p>
        </div>
        <p className="location__detailed--extraCostDesc">
          + ${this.state.pricePerAdditionalMile}/{units}{" "}
          {getTranslatedText(TRANSLATION_CONSTANTS.OVER)}{" "}
          {this.state.coveredMilesLimit} {units}s
        </p>
        <div className="location__detailed--extraCostTotal">
          <div className="location__detailed--extraCostTotalInner">
            <p>
              {getTranslatedText(TRANSLATION_CONSTANTS.ESTIMATED_AMOUNT_DUE)}
            </p>
            <p>${overMileageCost}</p>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      serviceInfo,
      history,
      partnerDetails: {
        displayCode,
        experience: {
          overMileageEnabled,
          milesBased,
          ppuEnabled,
          benefitsEnabled,
          canDetermineFinalCost,
          towToEnabled,
          towAfterClosingHoursEnabled,
          serviceSelectionEnabled,
        },
      },
      commonBenefits,
      destination,
    } = this.props;

    const { towInfo = {} } = destination;

    const {
      overMileageDistance,
      overMileageCost,
      totalTowCost,
      distanceUnits,
      distanceFromCurrentLocation,
      unitsShorthand,
    } = towInfo;

    let benefitsCosts = getBenefitAmount(commonBenefits);

    let displayBenefitMessage =
      commonBenefits.benefits &&
      !overMileageEnabled &&
      !canDetermineFinalCost &&
      serviceInfo?.serviceText === TOW;

    let displayDestinationDetails, showModalScreens;
    if (destination.formatted_address && !destination.hasBeenConfirmed) {
      displayDestinationDetails = true;
    } else if (
      (destination && destination.hasBeenConfirmed) ||
      this.props.serviceInfo.id !== TOW_SERVICE_ID
    ) {
      showModalScreens = true;
    }

    let destinationIsClosedOrClosingSoon =
      (destination?.operatingHours?.status === "Closed" ||
        destination?.operatingHours?.status === "Closing Soon") &&
      towToEnabled &&
      !towAfterClosingHoursEnabled;

    return (
      <LocaleConsumer>
        {(getTranslatedText) => (
          <div role="main" className={"c-onload-animation"}>
            {this.state.displayOverMilesModal && (
              <AlertModal
                title=""
                customClass="noBenefits"
                content={
                  "Your destination can’t go over " +
                  this.state.coveredMilesLimit +
                  " " +
                  distanceUnits +
                  "s. Choose a recommended option or call customer service."
                }
                primaryButton="Call"
                primaryButtonCallback={this.handlePhoneCall}
                secondaryButton="OK"
                secondaryButtonCallback={this.closeOverMilesModal}
              />
            )}

            <div>
              <GoogleMap
                openServiceNotAvailableModal={this.openServiceNotAvailableModal}
                openInvalidAddressModal={this.openInvalidAddressModal}
                mapOptions={{
                  streetViewControl: false,
                  mapTypeControl: false,
                  zoomControl: false,
                  fullscreenControl: false,
                  center: {
                    lat: this.getLat(),
                    lng: this.getLng(),
                  },
                  styles: [
                    { featureType: "poi", stylers: [{ visibility: "off" }] },
                    {
                      featureType: "all",
                      stylers: [{ saturation: -20 }, { lightness: 10 }],
                    },
                  ],
                }}
              />

              <DetailedLocationReview
                displayCode={displayCode}
                destination={destination}
                closeDetailedLocationReview={this.closeDetailedLocationReview}
                isActive={this.state.renderDestinationFullDetailsModal}
              />

              {displayDestinationDetails ? (
                <div>
                  <div
                    role="link"
                    tabIndex="0"
                    data-testid={"location-details-container"}
                    className={`location__detailed__container ${
                      this.state.renderDestinationFullDetailsModal
                        ? "location__detailed__container--hide"
                        : ""
                    }`}
                  >
                    <div
                      className="location__detailed--handle"
                      onClick={() => this.getDetailedLocation()}
                      onKeyDown={() => this.getDetailedLocation()}
                    >
                      <SlideArrow color="dark" direction="up" />
                    </div>

                    <div
                      className={`${
                        overMileageDistance > 0 &&
                        "location__detailed--extraMiles"
                      } location__detailed light`}
                    >
                      <div className="location__detailed--left">
                        <h2>{destination.name}</h2>
                        <address>{titleCase(destination.vicinity)}</address>
                        {destination.types !== "street_address" && (
                          <div>
                            <p>
                              {getTranslatedOperatingHoursText(
                                destination.operatingHours?.text,
                                getTranslatedText
                              )}
                            </p>
                          </div>
                        )}
                        <StarRating
                          starRating={
                            destination.rating && destination.rating.toFixed(1)
                          }
                          reviews={destination.user_ratings_total}
                          baseColor="orange"
                        />
                      </div>

                      <div className="location__detailed--right">
                        {!commonBenefits.benefits &&
                          overMileageDistance <= 0 &&
                          !serviceSelectionEnabled && (
                            <p className="price">${totalTowCost}</p>
                          )}
                        <p>{`${distanceFromCurrentLocation} ${unitsShorthand}`}</p>
                      </div>
                    </div>

                    {!commonBenefits.benefits &&
                      overMileageDistance > 0 &&
                      this.ppuExtraCostBreakdown(
                        serviceInfo,
                        ppuEnabled,
                        benefitsEnabled,
                        overMileageDistance,
                        commonBenefits,
                        destination,
                        getTranslatedText
                      )}

                    {milesBased &&
                      overMileageEnabled &&
                      commonBenefits.benefits &&
                      overMileageDistance > 0 &&
                      this.benefitUserExtraCostBreakdown(
                        overMileageDistance,
                        overMileageCost,
                        distanceUnits,
                        getTranslatedText
                      )}

                    {destinationIsClosedOrClosingSoon && (
                      <React.Fragment>
                        <div className="location__detailed--handle">
                          <p></p>
                        </div>
                        <div className="store-status">
                          {!destination.isSecureFacility ? (
                            <p>{`${getTranslatedText(
                              TRANSLATION_CONSTANTS.LOCATION_IS
                            )} ${getTranslatedText(
                              statusTranslatedText(
                                destination.operatingHours?.status.toLowerCase()
                              )
                            )}, ${getTranslatedText(
                              TRANSLATION_CONSTANTS.INSTRUCTIONS_CUSTOMER_SERVICE
                            )}`}</p>
                          ) : (
                            <p>{`${getTranslatedText(
                              TRANSLATION_CONSTANTS.THIS_LOCATION_IS
                            )} ${getTranslatedText(
                              statusTranslatedText(
                                destination.operatingHours?.status.toLowerCase()
                              )
                            )}, ${getTranslatedText(
                              TRANSLATION_CONSTANTS.SECURITY_FACILITY
                            )}`}</p>
                          )}
                        </div>
                      </React.Fragment>
                    )}

                    {displayBenefitMessage && (
                      <div className="u-hr-2-left u-hr-2">
                        <div className="u-flex u-flex--top u-flex--between">
                          <p className="u-text-bold benefitsTitle">
                            {getTranslatedText(
                              TRANSLATION_CONSTANTS.COVERAGE_UP_TO
                            )}
                          </p>
                          <p>{benefitsCosts ? `$${benefitsCosts}` : null}</p>
                        </div>

                        <div className="u-flex u-flex--top u-flex--between u-align-left">
                          <p className="memberTowNote u-vr-3">
                            {getTranslatedText(
                              TRANSLATION_CONSTANTS.FINAL_COST_WILL_BE_DETERMINED_PROVIDER
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`location__button ${
                      this.state.renderDestinationFullDetailsModal
                        ? "location__button--shadow"
                        : ""
                    }`}
                  >
                    {destinationIsClosedOrClosingSoon &&
                    !destination.isSecureFacility ? (
                      <Button
                        size="lg"
                        hasUpgrade
                        id="handle-phone-call"
                        onClick={this.handlePhoneCall}
                        utils={{
                          fullWidth: true,
                          vrTop: "sm",
                        }}
                      >
                        {getTranslatedText(
                          TRANSLATION_CONSTANTS.CALL_CUSTOMER_SERVICE
                        )}
                      </Button>
                    ) : (
                      <Button
                        size="lg"
                        hasUpgrade
                        id="confirm-drop-off-location"
                        onClick={() =>
                          this.confirmDestination(
                            overMileageCost,
                            overMileageDistance,
                            distanceFromCurrentLocation,
                            unitsShorthand
                          )
                        }
                        disabled={!destination.name}
                        utils={{
                          fullWidth: true,
                          vrTop: "sm",
                        }}
                      >
                        {getTranslatedText(
                          TRANSLATION_CONSTANTS.CONFIRM_DROP_OFF_LOCATION
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              ) : null}

              {showModalScreens ? (
                <ServiceDetailsOverlayContainer
                  partnerCode={displayCode}
                  history={history}
                />
              ) : null}

              {this.state.serviceNotAvailable ? (
                <ModalDialogAlt
                  id="choosehelp"
                  title={getTranslatedText(
                    TRANSLATION_CONSTANTS.SERVICE_NOT_AVAILABLE
                  )}
                  isSlideDrawer
                  hideTrigger
                  showCloseBtn={true}
                  isActive={this.state.serviceNotAvailable}
                  onClose={this.closeServiceNotAvailableModal}
                  footer={
                    <button
                      className="btn btn-info"
                      onClick={this.closeServiceNotAvailableModal}
                      onKeyDown={this.closeServiceNotAvailableModal}
                    >
                      {" "}
                      Ok{" "}
                    </button>
                  }
                >
                  <div className="text_left">
                    <p>
                      {getTranslatedText(
                        TRANSLATION_CONSTANTS.WE_SORRY_SERVICE_NOT_AVAILABLE
                      )}
                    </p>
                  </div>
                </ModalDialogAlt>
              ) : null}
              {this.state.invalidAddress ? (
                <ModalDialogAlt
                  id="invalid_address"
                  title="Invalid Address"
                  isSlideDrawer
                  hideTrigger
                  showCloseBtn={true}
                  isActive={this.state.invalidAddress}
                  onClose={this.closeInvalidModal}
                  footer={
                    <button
                      className="btn btn-info"
                      onClick={this.closeInvalidModal}
                      onKeyDown={this.closeInvalidModal}
                    >
                      {" "}
                      {getTranslatedText(TRANSLATION_CONSTANTS.OK)}{" "}
                    </button>
                  }
                >
                  <div className="text_left">
                    <p>
                      {getTranslatedText(
                        TRANSLATION_CONSTANTS.ENTER_ADDITIONAL_DETAILS_CURRENT_LOCATION
                      )}{" "}
                      &#40;
                      {getTranslatedText(TRANSLATION_CONSTANTS.I_E_STREET_NAME)}
                      &#41;.
                    </p>
                  </div>
                </ModalDialogAlt>
              ) : null}
            </div>
          </div>
        )}
      </LocaleConsumer>
    );
  }
}

function mapStateToProps(state) {
  return {
    serviceInfo: state.serviceInfo,
    destination: state.destination,
    partnerDetails: state.partnerDetails.partnerDetails,
    serviceDetails: state.serviceDetails,
    serviceRequest: state.serviceRequestPayload.serviceRequest,
    commonBenefits: state.commonBenefits,
    submitRequest: state.submitRequest,
    hasPepSession: hasPepSessionSelector(state),
    omniStreamlined: getOmniStreamlinedFlag(state),
  };
}

export default connect(mapStateToProps, {
  updateDestinationAddress,
  updateDestination,
  updateServiceInfo,
  updatePpuFlag,
  updateLoggedInFlag,
  updateCCardInfo,
  clearCCardInfo,
  updateTowDistance,
  updateServiceTransition,
})(Location);
