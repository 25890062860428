import {takeEvery, call, put} from 'redux-saga/effects';
import {
  loadFeatureFlag,
  loadFeatureFlagFailed,
  loadFeatureFlagSuccess
} from "../action";
import {getOmniStreamlinedFlag} from "../api";

function* onInit() {
  console.log("HERE ON INIT")

  const [_ , route, partnerCode] = window.location.pathname.split("/");
  yield put(loadFeatureFlag({route, partnerCode}))

  try {
    if(route === 'wd') {
      /**
       * For wd route, we should not call the getOmniStreamlinedFlag API as it is not applicable for wd.
       * Hence return early to avoid unnecessary API calls.
       */
      yield put(loadFeatureFlagSuccess("")); // Default to empty value for wd
      return;
    }
    const response = yield call(getOmniStreamlinedFlag, partnerCode);

    yield put(loadFeatureFlagSuccess(response.data)); // Dispatch success action with the response data
  } catch(error) {
    yield put(loadFeatureFlagFailed(error))
  }



}

export function* watchInitSaga() {
  yield takeEvery("@@App/INIT", onInit);
}
