import {takeEvery, put, call, select} from 'redux-saga/effects';
import {
  commonBenefitsFailed,
  REQUESTED_SUBMIT_REQUEST,
  updateServiceTransition,
  updateSessionId,
} from '../action';
import {
  submitServiceRequest,
  createMemberSessionOpenApi,
  fraudMonitor
} from '../api';
import {datadogRum} from "@datadog/browser-rum";
import {
  getAccidentQuestionResponse,
  getCommonBenefits,
  getPartnerDetails,
  getServiceInfo,
  hasPepSessionSelector,
  getOmniStreamlinedFlag
} from "../selector";
import {
  submitServiceRequestFailed,
  submitServiceRequestSuccess
} from "../action/submitServiceRequest-action";
import {cleanServiceRequestPayload, isScheduleService} from '../utils/serviceRequestPayloadUtils'
import CONSTANTS, {
  JJ_AND_AMC,
  TOW,
  TOW_SERVICE_ID
} from '../app-consts/appConstants';
import _isEmpty from "lodash/isEmpty";
import {UNABLE_TO_PROCESS_REQUEST} from "../app-consts/errorMessageConstants";

export function* submitServiceRequestSaga(action) {
  const commonBenefits = yield select(getCommonBenefits);
  const partnerDetails = yield select(getPartnerDetails);
  const serviceInfo = yield select(getServiceInfo);
  const hasPepSession = yield select(hasPepSessionSelector);

  const omniStreamlined = yield select(getOmniStreamlinedFlag);
  const {partnerCode, experience, route} = partnerDetails;
  const {benefitsEnabled, ppuEnabled} = experience;
  const serviceType = serviceInfo.service;

  const payload = action.payload

  if (partnerCode === "JJ" && serviceInfo.id === TOW_SERVICE_ID) {
    const accidentQuestionResponse = yield select(getAccidentQuestionResponse);
    payload.accidentQuestionResponse = accidentQuestionResponse;
  }

  let sessionId = '';
  const values = new URLSearchParams(window.location.search);
  const urlSessionId = values.get('session');
  const denyRequest = {
    firstName: action.payload?.serviceRequest?.firstName,
    lastName: action.payload?.serviceRequest?.lastName,
    callbackNumber: action.payload?.serviceRequest?.contactNumber,
    membershipNumber: commonBenefits?.memberId, //TODO we need the check like below or just this is fine
    vin: action.payload?.serviceRequest?.vin,
    smtpAddress: action.payload?.serviceRequest?.email,
    partnerCode: partnerCode
    // disablementAddress: action.payload?.serviceRequest?.disablementAddress?.addressLine1,
    // disablementCityName: action.payload?.serviceRequest?.disablementAddress?.city,
    // disablementCountryCode: action.payload?.serviceRequest?.disablementAddress?.country,
    // disablementStateCode: action.payload?.serviceRequest?.disablementAddress?.state,
  }
  if(!_isEmpty(denyRequest) && JJ_AND_AMC.includes(partnerCode)){
    const denyResponse = yield call(fraudMonitor, denyRequest);
    if(denyResponse?.success && denyResponse?.body?.statusText === 'DENY' ){
      yield put(commonBenefitsFailed({
        message: CONSTANTS.SERVICE_REQUEST_DENIED
      }));
      yield put(updateServiceTransition(
        {
          errorMessageContent: UNABLE_TO_PROCESS_REQUEST,
          declineType: CONSTANTS.SERVICE_REQUEST_DENIED
        }));
      return;
    }
  }
  if (!payload.sessionId && !commonBenefits.noBenefits && payload.serviceRequest.loggedIn !== 'N' && urlSessionId) {
    datadogRum.addAction(`SessionID not available in request payload, Picked from url `, {
      displayCode: partnerDetails?.displayCode,
      serviceText: serviceInfo?.serviceText,
      payloadSessionId: payload?.sessionId,
      urlSessionId: urlSessionId
    });
    yield put(updateSessionId(urlSessionId))
    payload.sessionId = urlSessionId;
  }
  try {
    if (!payload.sessionId ||
      ( !omniStreamlined && (benefitsEnabled && (commonBenefits.noBenefits === true || payload.serviceRequest.loggedIn === 'N') ||
      (hasPepSession && serviceInfo.serviceText !== TOW)))
    ) {
      datadogRum.addAction(`Submit Rescue PPU`, {
        displayCode: partnerDetails?.displayCode,
        sessionId: payload?.sessionId,
        omniStreamlined: !!omniStreamlined,
        benefitsEnabled,
        noBenefits: commonBenefits?.noBenefits,
        loggedIn: payload?.serviceRequest?.loggedIn,
        hasPepSession,
        serviceText: serviceInfo?.serviceText,
      });
      const memberId = (commonBenefits.noBenefits === true || payload.serviceRequest.loggedIn === 'N') ? '' : commonBenefits.memberId;
      const type = partnerDetails.ppuSessionType;
      const appId = payload.serviceRequest.appId;

      const latitude = payload.serviceRequest.disablementAddress.latitude;
      const longitude = payload.serviceRequest.disablementAddress.longitude;

      const options = {
        memberId,
        type,
        partnerCode,
        appId,
        latitude,
        longitude,
        flippedToPpu: ppuEnabled
      };
      let sessionResponse;
      try {
        sessionResponse = yield call(createMemberSessionOpenApi, route, options);
      } catch (error) {
        let errorToDisplay = error.response?.data?.message || error.response?.data;
        datadogRum.addError(new Error(`Submit Service Request not called due to Session API failed with error: ${JSON.stringify(errorToDisplay)}, Action: ${JSON.stringify(action)}`));
        yield put(submitServiceRequestFailed());
      }
      sessionId = sessionResponse.data.id
      if(sessionId) {
        yield put(updateSessionId(sessionId))
      }
      payload.sessionId = sessionResponse.data.id
    }

    if(!omniStreamlined) {
      cleanServiceRequestPayload(serviceInfo.id, payload.serviceRequest)
    }
    let response = yield call(submitServiceRequest, payload, partnerCode, serviceType, partnerDetails.experience, hasPepSession, omniStreamlined);

    if (response.status === 200 && isScheduleService(payload)) {
      const requestScheduleDateTime = payload?.serviceRequest?.additionalFields?.[0]?.find(
        ({ key = "" }) => key === "requestscheduledatetime"
      )
      datadogRum.addAction("Scheduled Service Submission", {
        displayCode: partnerDetails?.displayCode,
        currentDate: new Date().toLocaleString("en-US"),
        requestScheduleDateTime
      });
    };
    yield put(submitServiceRequestSuccess(response.data));
  } catch (error) {
    // let errorToDisplay = error.response?.data?.error?.errors[0] || error.response?.data || error;
    // errorToDisplay = _isNil(errorToDisplay) ? error?.stack : JSON.stringify(errorToDisplay);
    datadogRum.addError(new Error(`Submit Service Request API failed with error, Action: ${JSON.stringify(action)}`), error);
    yield put(submitServiceRequestFailed());
  }
}

export function* watchSubmitRequestSaga() {
  yield takeEvery(REQUESTED_SUBMIT_REQUEST, submitServiceRequestSaga);
}
