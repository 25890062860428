import React from 'react'
import ReactDOM from 'react-dom'
import {Route, BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {getStore} from './getStore'
import App from './App'
import './styles/main.css'
import 'focus-visible/dist/focus-visible.js'
import { PersistGate } from 'redux-persist/integration/react'

const {store, persistor} = getStore();
store.dispatch({ type: '@@App/INIT' }); // Dispatching INIT action to initialize sagas

if(window.location.hostname.includes('local') && window.location.pathname.includes('OmniAssist') && window.location.search.includes('conversationId')){
  window.allstateRDS.logger.log = () => {}
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Route component={App}/>
      </BrowserRouter>
    </PersistGate>
  </Provider>, document.getElementById('root'));
